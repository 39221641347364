import axios from 'axios';
import dayjs from 'dayjs';
import {
    buildUrlParams, createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { getApiUrl } from '@/utils/api';

function editDistance(str1, str2) {
    const s1 = str1.toLowerCase();
    const s2 = str2.toLowerCase();

    const costs = [];
    for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
            if (i === 0) costs[j] = j;
            else if (j > 0) {
                let newValue = costs[j - 1];
                if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
                    newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                }
                costs[j - 1] = lastValue;
                lastValue = newValue;
            }
        }
        if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

function similarity(s1, s2) {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength === 0) {
        return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

const state = {
    ...getDefaultDataState({ hasPagination: false }),
    countData: [{
        metadata: {
            id: '',
            name: '',
            lat: 0,
            lng: 0,
        },
        low: 0,
        medium: 0,
        high: 0,
    }],
    camerasCoordinates: {
        '8e33ac3d-868b-47a5-aeed-379af48e4e35': { lat: -36.94344, lng: 174.83098 },
        'b5de18c8-833d-46c7-baf0-f655eee01633': { lat: -36.94299, lng: 174.83119 },
        '2ca94190-d298-4e7f-9033-7bcf0eea5443': { lat: -36.94325, lng: 174.83026 },
        '439bfcf2-0a8d-4731-ab77-ad3a2462fd28': { lat: -36.94272, lng: 174.83079 },
        'b2d5bbb0-041e-473c-b61c-42ee5957f636': { lat: -36.94382, lng: 174.83062 },
        'd9fa56cb-fe3d-4a03-aac1-64c511c76ee2': { lat: -36.94351, lng: 174.83063 },
        '39a892c4-ff00-4302-b617-debe0b00babb': { lat: -36.94288, lng: 174.83062 },
        '64a4fef9-2e94-4a1e-929c-d47501ba1df0': { lat: -36.94316, lng: 174.83058 },
        '893d9e37-39a2-49ce-b818-b367b9b86678': { lat: -36.9431, lng: 174.83135 },
        '65ed0c19-3cc6-4bbb-bebf-1a49bc2fb3b8': { lat: -36.94334, lng: 174.83037 },
        '8c74590f-2e22-48a0-a751-dafc843cf397': { lat: -36.94325, lng: 174.83119 },
        'd035b3e6-c1c3-4e23-b584-aa676e826439': { lat: -36.94334, lng: 174.83083 },
        '26bc6003-4303-4a0f-9d0a-17dbaee71ff1': { lat: -36.94345584, lng: 174.8300973 },
        'a3c53448-3979-4e92-9cf2-11ea75907b23': { lat: -36.94364, lng: 174.83081 },
        '393af539-1747-4456-8ff9-25c1b84d9fd0': { lat: -36.94307, lng: 174.83044 },
        'cb85e36d-1952-43d3-ac2f-1e838d7b77ca': { lat: -36.94313, lng: 174.83102 },
    },
    exclude: [
        '1a981d42-b056-4a2d-8188-6df24c94b0c4',
        'e96a2641-0de7-4409-81eb-67b534982815',
        'f7145974-2148-4b1f-8f1c-dea315251b3d',
        '8197b745-444d-4fe3-bf6a-88184834b0a0',
        '680656b3-2c72-4169-90c8-7b42e0874209',
        'b5bbd95a-6697-4622-a413-fc45e8fa8409',
        '0f820d8a-eee6-4dae-b904-9b8340ed62dd',
        'effa7f1a-a736-4b20-a2b0-06e25032bf3c',
    ],
    isLoading: true,
};

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    getCountData(state) {
        return state.countData;
    },

    getCamerasCoordinates(state) {
        return state.camerasCoordinates;
    },

    isLoading(state) {
        return state.isLoading;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),

    // TODO: add lat and lng to cameras on the backend
    setCountData(state, payload) {
        state.countData = payload.filter((item) => !state.exclude.includes(item.camera))
            .map((item) => ({
                metadata: {
                    id: item.camera,
                    name: item.name,
                    lat: getters.getCamerasCoordinates(state)[item.camera].lat,
                    lng: getters.getCamerasCoordinates(state)[item.camera].lng,
                },
                low: item.low_risk,
                medium: item.medium_risk,
                high: item.high_risk,
            }));
    },

    setIsLoading(state, payload) {
        state.isLoading = payload;
    },
};

const actions = {
    async loadData({
        state, commit, rootState,
    }) {
        const { dataFilters } = rootState;
        const { startDate, endDate, selectedTags } = dataFilters;

        const selectedTagLabels = selectedTags.map((tag) => tag.label);

        const from = dayjs(startDate).format('YYYY-MM-DDTHH:mm:00');
        const to = dayjs(endDate).format('YYYY-MM-DDTHH:mm:00');

        const params = buildUrlParams({
            additional: {
                from,
                to,
            },
        });

        const path = `hsafety/risk_score/count/cameras/${from}/to/${to}/`;

        const dataHash = createDataHash(path, params, selectedTagLabels);

        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setDataHash', { dataHash });
        commit('setStatus', { status: 'loading' });
        commit('setIsLoading', true);

        try {
            const response = await axios({
                url: getApiUrl({ path }),
            });

            if (selectedTagLabels.length > 0) {
                commit('setCountData', response.data.filter((item) => selectedTagLabels.some((label) => similarity(item.name, label) > 0.9)));
            } else {
                commit('setCountData', response.data);
            }

            commit('setStatus', { status: 'success' });
        } catch (e) {
            console.error('Error loading data:', e);
            commit('setStatus', { status: 'error' });
        }

        commit('setIsLoading', false);

        return Promise.resolve(true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
