import axios from 'axios';
import dayjs from 'dayjs';
import {
    buildUrlParams, createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { getApiUrl } from '@/utils/api';

const state = {
    ...getDefaultDataState({ hasPagination: false }),
    countData: {
        low: [],
        medium: [],
        high: [],
    },
    tickCount: 8,
    tickDuration: 0,
};

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    getCountData(state) {
        return state.countData;
    },

    getTickCount(state) {
        return state.tickCount;
    },

    getTickDuration(state) {
        return state.tickDuration;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),

    setCountData(state, payload) {
        state.countData = {
            low: payload.low_risk,
            medium: payload.medium_risk,
            high: payload.high_risk,
        };
    },

    setTickDuration(state, payload) {
        state.tickDuration = payload;
    },

    setTickCount(state, payload) {
        state.tickCount = payload;
    },
};

const actions = {
    async loadData({
        state, commit, rootState,
    }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const from = dayjs(startDate).format('YYYY-MM-DDT00:00:00');
        const to = dayjs(endDate).format('YYYY-MM-DDT23:59:00');

        const tickDuration = 1 * 24 * 60 * 60 * 1000; // 1 day
        const tickCount = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;

        const params = buildUrlParams({
            additional: {
                from,
                to,
            },
        });

        const dataHash = createDataHash(`hsafety/risk_score/count/${from}/to/${to}/`, params);

        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setDataHash', { dataHash });
        commit('setStatus', { status: 'loading' });
        commit('setTickDuration', tickDuration);
        commit('setTickCount', tickCount);

        let toTemp = dayjs(startDate);
        const promises = [];
        for (let i = 0; i < state.tickCount; i++) {
            const path = `hsafety/risk_score/count/${toTemp.format('YYYY-MM-DDT00:00:00')}/to/${toTemp.format('YYYY-MM-DDT23:59:00')}/`;

            promises.push(axios(
                {
                    url: getApiUrl({ path }),
                },
            ));

            toTemp = dayjs(toTemp).add(state.tickDuration, 'millisecond');
        }

        try {
            const responses = await Promise.all(promises);

            const payload = {
                low_risk: [],
                medium_risk: [],
                high_risk: [],
                critical_risk: [],
            };

            responses.forEach((response) => {
                payload.low_risk.push(response.data.low_risk);
                payload.medium_risk.push(response.data.medium_risk);
                payload.high_risk.push(response.data.high_risk);
                payload.critical_risk.push(response.data.critical_risk);
            });

            commit('setCountData', payload);
            commit('setStatus', { status: 'success' });
        } catch (e) {
            console.error('Error loading data:', e);
            commit('setStatus', { status: 'error' });
        }

        return Promise.resolve(true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
