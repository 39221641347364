<template>
    <div class="media-video">
        <b-loading
            :active="isLoading"
            :can-cancel="false"
            :is-full-page="false"
        />
        <template v-if="url">
            <template v-if="isError">
                <div class="media-video__error">
                    <div class="content">
                        <p>{{ message }}</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="media-video__container__wrapper">
                    <div ref="container" class="media-video__container">
                        <div
                            ref="videoContainer"
                            class="media-video__video-container"
                            :class="{ 'is-fullscreen': isFullscreen }"
                        >
                            <div
                                ref="scene"
                                class="media-video__scene"
                                :class="{ 'scene-fullscreen': isFullscreen }"
                            >
                                <div
                                    class="media-video__wrapper"
                                    :class="{ 'is-dragging': isDragging || !isDropdownActive }"
                                    @click="togglePlayVideo()"
                                    @mouseenter="showOverlay = true"
                                    @mouseleave="showOverlay = false"
                                    :style="wrapperStyles"
                                >
                                    <video
                                        v-if="!isPreview"
                                        :src="url"
                                        :poster="poster"
                                        autoplay
                                        :loop="false"
                                        @error="onVideoError"
                                        @play="onVideoPlay"
                                        :ref="`video-${url}`"
                                        @loadeddata="onVideoLoad"
                                        @dblclick="toggleFullScreen"
                                        :controls="false"
                                        :style="videoStyles"
                                    />
                                    <div
                                        class="media-video__preview"
                                        :style="videoStyles"
                                        @click="play()"
                                        v-else
                                    >
                                        <img
                                            v-if="poster"
                                            :src="poster"
                                            alt=""
                                            class="media-video__preview-image"
                                            ref="image"
                                            @load="onImageLoad"
                                        />
                                        <button type="button" class="media-video__preview-cta">
                                            <b-icon icon="play" />
                                        </button>
                                    </div>
                                    <video-annotation
                                        :width="imageSize.width"
                                        :height="imageSize.height"
                                        :scale="scale"
                                        :objects="canvasObjs"
                                        :mode="mode"
                                        :selectedTool="selectedTool"
                                        :selectFunctions="selectedFunctions"
                                        :selectedObjs="selectedObjs"
                                        :createObject="createObject"
                                        @object-updated="handleObjectUpdated"
                                        :currentFrame="currentFrame"
                                        :showDurationDialog="showDurationDialog"
                                        :durationObj="durationObj"
                                        :durationFrames="durationFrames"
                                        :totalVideoFrameCount="totalVideoFrameCount"
                                        @close-duration-dialog="handleDurationDialogClose"
                                    />
                                    <media-image-h-s-canvas
                                        v-if="isHS && !isPreview"
                                        class="media-video__canvas"
                                        :is-visible="!isLoading"
                                        :width="imageSize.width"
                                        :height="imageSize.height"
                                        :detections="detections"
                                        :camera="camera"
                                        :scale="scale"
                                        :translate="translate"
                                        :displayRoi="displayRoi"
                                        :style="{
                                            width: `${this.imageSize.width}px`,
                                            height: `${this.imageSize.height}px`,
                                        }"
                                        :displayHeatmap="displayHeatmap"
                                        :heatmapImage="heatmapImage"
                                        :imageConfig="imageConfig"
                                        :showSpeed="showSpeed"
                                        :showPPECheck="showPPECheck"
                                        :showTrail="showTrail"
                                        :showProximity="showProximity"
                                        :showPrivacy="showPrivacy"
                                        :showBb="showBb"
                                    />
                                    <media-image-canvas
                                        v-if="!isHS && !isPreview"
                                        class="media-video__canvas"
                                        :is-visible="!isLoading"
                                        :width="imageSize.width"
                                        :height="imageSize.height"
                                        :detections="detections"
                                        :image-type="imageType"
                                        :scale="scale"
                                        :translate="translate"
                                    />
                                    <div
                                        v-if="!isPreview"
                                        class="media-video__overlay"
                                        :class="{ 'is-visible': showOverlay || isDragging || isDropdownActive }"
                                        :style="overlayStyles"
                                    >
                                        <div class="media-video__overlay__container" :class="{ 'is-active': isDropdownActive || isDragging }">
                                            <div class="media-video__overlay__container__left">
                                                <b-button @click.prevent.stop="togglePlayVideo" class="play-pause-button">
                                                    <b-icon
                                                        :icon="pauseIcon"
                                                        type="is-white"
                                                        size="is-medium"
                                                        clickable
                                                        class="play-pause-button"
                                                    />
                                                </b-button>
                                                <div>
                                                    <h3 class="media-video__overlay__container__timestamp">{{ video ? getTimeStampText(video.currentTime) : '--:--' }}</h3>
                                                </div>
                                            </div>
                                            <div class="media-video__overlay__track" id="videoScrubberContainer" @mousedown="handleScrubberMouseDown">
                                                <div @click="handleClickScrubber" @mousedown="handleScrubberMouseDown" class="media-video__overlay__bars">
                                                    <div
                                                        v-for="
                                                            [frameNum, {
                                                                count,
                                                                maxRisk,
                                                                distanceData = [],
                                                                isSafe = false,
                                                                isAnnotated = false,
                                                            }] in Array.from(detectionFrameDataMap)
                                                        "
                                                        :key="`marker-${frameNum}`"
                                                        @mousedown="seekToFrame(frameNum)"
                                                        :class="`frame-${frameNum}`"
                                                        class="frame-marker"
                                                        :style="getBarStyles(frameNum, count, maxRisk, distanceData.length > 0)"
                                                    >
                                                        <div class="indicator-parent" :style="indicatorStyles">
                                                            <div v-if="isAnnotated" class='annotated-indicator' :style="indicatorDotStyles" />
                                                            <div v-if="doShowSafeIndicator(isSafe, maxRisk)" class="safe-indicator" :style="indicatorDotStyles" />
                                                        </div>
                                                        <b-tooltip
                                                            placement="is-top"
                                                            type="is-black"
                                                            v-if="distanceData.length > 0"
                                                        >
                                                            <div :style="getToolStyles(count)" />

                                                            <template #content>
                                                                <div>
                                                                    <div class="tooltip-grid">
                                                                        <p>Risk Score: <span :class="calcRiskScoreClass(maxRisk)">{{ maxRisk }}</span></p>
                                                                        <!--
                                                                        <div
                                                                            v-for="(distance, index) in sortAndFilterDistanceData(distanceData)" :key="`tool-${frameNum}-${index}`" class="tooltip-row"
                                                                        >
                                                                            <p class="is-unselectable">
                                                                                <span>{{ transformDistanceType(distance.type) }} : </span>
                                                                                <span :class="getRiskLevelClass(distance.riskLevel)">
                                                                                    {{ transformRiskLevel(distance.riskLevel) }}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                        -->
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </b-tooltip>
                                                    </div>
                                                </div>
                                                <div style="width: 100%; height: 5px; background-color: grey; position: relative;" @click="handleClickScrubber">
                                                    <div :style="scrubberBarStyles()" class="scrubber-bar" id="videoScrubberBar" @mousedown="handleScrubberMouseDown" />
                                                    <div @mousedown="handleScrubberMouseDown" class="media-video__overlay__progress" :style="getProgressStyles()" />
                                                </div>
                                            </div>
                                            <div class="media-video__overlay__container__right">
                                                <div class="media-video__overlay__container__right__left">
                                                    <b-button @click="toggleFullScreen" class="full-screen-button">
                                                        <b-icon
                                                            :icon="fullScreenIcon"
                                                            type="is-white"
                                                            size="is-medium"
                                                            clickable
                                                            class="full-screen-button"
                                                        />
                                                    </b-button>
                                                    <div>
                                                        <h3 class="media-video__overlay__container__timestamp">{{ video ? getTimeStampText(video.duration) : '--:--' }}</h3>
                                                    </div>
                                                </div>
                                                <div class="media-video__overlay__container__right__right">
                                                    <b-dropdown
                                                        position="is-top-right"
                                                        no-caret
                                                        @active-change="onDropdownActiveChange"
                                                    >
                                                        <b-button
                                                            class="pop-up-button"
                                                            slot="trigger"
                                                        >
                                                            <b-icon
                                                                class="pop-up-button"
                                                                icon="dots-vertical"
                                                                size="is-medium"
                                                                type="is-white"
                                                            />
                                                        </b-button>

                                                        <!-- Dropdown menu items -->
                                                        <b-dropdown-item @click="copyVideoId">
                                                            <b-icon icon="content-copy" />
                                                            <span>Copy Video Id</span>
                                                        </b-dropdown-item>

                                                        <b-dropdown-item @click="downloadVideo">
                                                            <b-icon icon="download" />
                                                            <span>Download Video</span>
                                                        </b-dropdown-item>

                                                        <!-- Add more dropdown items as needed -->
                                                    </b-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!--
                    <div
                        class="media-video__timeline"
                        :style="{
                            height: `${this.imageSize.height}px`,
                            width: `15%`,
                        }"
                    >
                        <ul
                            v-if="timestamps.length > 0"
                        >
                            <div class="media-video__timeline_title">List of observation times:</div>
                            <li
                                v-for="(timestamp, index) in timestamps"
                                :key="`timestamp-${timestamp.frame_number}-${index}`"
                                @click="seekToTimestamp(timestamp.start)"
                            >
                                <span class="media-video__timeline_bullet">•</span>
                                <span class="media-video__timeline_timestamp">{{ `${formatTime(timestamp.start)}-${formatTime(timestamp.end)}` }}</span>
                                <span class="media-video__timeline_violation">{{ ` ${timestamp.violationText}` }}</span>
                                <span
                                    :class="calcRiskScoreClass(timestamp.riskLevel)"
                                >
                                    {{ ` [${timestamp.riskLevel}]` }}
                                </span>
                            </li>
                        </ul>
                        <ul v-else-if="isPlaying && timestamps.length === 0">
                            <li>No detections found</li>
                        </ul>
                        <ul v-else>
                            <li>Play video to see observation timestamps</li>
                        </ul>
                    </div>
                    -->
                    <div v-if="!isLoading" id="annotation-toolbar" class="toolbar">
                        <b-tooltip v-if="isViewMode" label="Annotate frame" position="is-left" type="is-dark" animated>
                            <b-button :disabled="isPreview" @click="handleModeButtonClick(MODES.EDIT)" class="mode-button edit-button">
                                <b-icon icon="pencil" />
                            </b-button>
                        </b-tooltip>
                        <b-tooltip v-else label="Save Annotation" position="is-left" type="is-dark" animated>
                            <b-button @click="handleModeButtonClick(MODES.VIEW)" class="be-button mode-button is-success" :loading="isSaving" :disabled="isSaving">
                                <b-icon icon="content-save" />
                            </b-button>
                        </b-tooltip>

                        <div
                            v-if="isEditMode"
                            class="toolbar__edit"
                        >
                            <b-tooltip label="Add Comment" position="is-left" type="is-dark" animated>
                                <b-button @click="setTool(TOOLS.COMMENT)" :class="{ 'is-primary': isCommentToolActive }" class="mode-button">
                                    <b-icon icon="comment" />
                                </b-button>
                            </b-tooltip>

                            <b-tooltip label="Select" position="is-left" type="is-dark" animated>
                                <b-button @click="setTool(TOOLS.SELECT)" :class="{ 'is-primary': isSelectToolActive }" class="mode-button">
                                    <b-icon icon="cursor-default" />
                                </b-button>
                            </b-tooltip>
                            <!--
                            <b-button @click="setTool(TOOLS.RESIZE)" :class="{ 'is-primary': isResizeToolActive }">
                                <b-icon icon="resize" />
                                <span>Resize</span>
                            </b-button>
                            -->
                            <!--
                            <h3>Create Object</h3>
                            <b-button @click="setTool(TOOLS.RECT)" :class="{ 'is-primary': isRectToolActive }">
                                <b-icon icon="select" />
                                <span>Rect</span>
                            </b-button>
                            -->
                            <div v-if="selectedObjs.length > 0 && isEditMode" class="tool-bar">
                                <hr class="is-divider" />
                                <b-tooltip label="Delete Selected" position="is-left" type="is-dark" animated>
                                    <b-button @click="deleteSelectedObjs" class="is-delete mode-button">
                                        <b-icon icon="delete" />
                                    </b-button>
                                </b-tooltip>
                                <!--
                                <b-button @click="handleChangeDuration">Duration</b-button>
                                -->
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
        <template v-else>
            <div class="media-video__error">
                <div class="content">
                    <p>{{ message }}</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import MediaImageCanvas from '@/components/local/data/MediaImageCanvas';
import MediaImageHSCanvas from '@/components/local/data/MediaImageHSCanvas';
import VideoAnnotation from '@/components/local/data/VideoAnnotation';
import { binarySearchClosestSmallerNumber } from '@/utils/number';
import { getTansformationConfig } from '@/utils/canvas';
import {
    STATUS_LOADING,
    STATUS_PENDING,
    STATUS_READY,
    MAX_INTERPOLATION_INTERVAL,
    BOX_FADE_OUT_TIME,
} from '@/utils/constants';
import { getPPEDetections, isViolation } from '@/utils/hs/helpers';
import { getRiskScoreClass } from '../../../utils/hs/helpers';
import videoAnnotationMixin from '@/mixins/videoAnnotation/videoAnnotationMixin';
import { TOOLS, MODES, OBJ_TYPES } from '@/components/local/data/VideoAnnotation/VideoAnnotationConstants';

export default {
    mixins: [videoAnnotationMixin],
    components: { VideoAnnotation, MediaImageCanvas, MediaImageHSCanvas },
    props: {
        videoId: {
            type: String,
            default: '',
        },
        active: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: '',
        },
        poster: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        camera: {
            type: [String, Object],
            default: null,
        },
        tracks: {
            type: Array,
            default: null,
        },
        proximityTracks: {
            type: Array,
            default: null,
        },
        displayRoi: {
            type: Boolean,
            default: true,
        },
        displayTags: {
            type: Boolean,
            default: false,
        },
        displayHeatmap: {
            type: Boolean,
            default: true,
        },
        heatmapImage: {
            type: HTMLImageElement,
            required: false,
            default: null,
        },
        detectionFrameDataMap: {
            type: Map,
            required: false,
            default: null,
        },
        showSpeed: {
            type: Boolean,
            default: true,
        },
        showPPECheck: {
            type: Boolean,
            default: true,
        },
        showTrail: {
            type: Boolean,
            default: true,
        },
        showProximity: {
            type: Boolean,
            default: true,
        },
        videoAnnotationTracks: {
            type: Object,
            default: () => ({}),
        },
        showPrivacy: {
            type: Boolean,
            default: true,
        },
        showBb: {
            type: Boolean,
            default: true,
        },
    },

    mounted() {
        // Set up event listeners
        this.$bus.$on('resize', this.resize);
        window.addEventListener('keyup', this.handleKeyUp);
        document.addEventListener('fullscreenchange', this.handleFullscreenChange);

        // Cleanup event listeners
        this.$once('hook:beforeDestroy', () => {
            this.$bus.$off('resize', this.resize);
            window.removeEventListener('keyup', this.handleKeyUp);
            document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
        });
    },

    data() {
        return {
            // Constants
            TOOLS,
            MODES,
            OBJ_TYPES,

            // Data
            isEditTools: false,
            imageStatus: STATUS_PENDING,
            isPreview: true,
            isPlaying: false,
            isError: false,
            message: 'No video provided',
            isHS: true,
            containerSize: null,
            videoContainerSize: null,
            imageType: 'vehicle',
            imageSize: {
                width: 0,
                height: 0,
            },
            previousFrameNumber: 0,
            scale: 1,
            translate: [0, 0],
            detections: {
                results_generic: [],
                results_proximity: [],
            },
            lastMediaTime: 0,
            lastFrameNumber: 0,
            fps: 0,
            fpsRounder: [],
            video: null,
            timestamps: [],
            thumbnail: null,

            currentTime: 0,
            currentFrame: 0,
            totalVideoFrameCount: 0,
            videoPaused: false,

            // For custom playback
            targetFPS: 3,
            isCustomPlayback: false,
            lastTimeStamp: 0,

            // For scrubber drag
            isDragging: false,
            isDropdownActive: false,
            wasPlaying: false,
            startX: 0,
            currentX: 0,

            barScale: 1,
            isFullscreen: false,
            showOverlay: false,

            disablePlay: false,

            containerHeight: 0,
            containerWidth: 0,
        };
    },

    watch: {
        videoAnnotationTracks: {
            handler(val) {
                this.setTracks(val);
            },
            immediate: true,
        },

        url: {
            handler() {
                this.stop();

                this.imageStatus = STATUS_PENDING;
                this.clearObjectBBs();
                this.clearObjectProximity();
                this.resetFPS();
            },
            immediate: true,
        },
        isReady() {
            this.resize();
        },
        displayTags: {
            handler(val) {
                if (!val) {
                    this.clearAnimationCanvas();
                    this.stopVideoAnimation();
                } else {
                    this.startVideoAnimation();
                }
            },
            immediate: true,
        },

        video(val) {
            if (!val) {
                return;
            }
            this.totalVideoFrameCount = this.video.duration * this.targetFPS;
        },

        detectionFrameDataMap: {
            handler(val) {
                let maxHeight = 0;
                Array.from(val).forEach(([, { count }]) => {
                    if (count > maxHeight) {
                        maxHeight = count;
                    }
                });

                const MAX_BAR_HEIGHT = 25; // has to be <= to height in pixels of the overlay__bars class
                this.barScale = Math.floor(MAX_BAR_HEIGHT / maxHeight);
            },
            immediate: true,
        },
    },

    computed: {
        // Computed values relating to the TOOL BAR <-- bounds for tool bar computed values
        // easy to find if extracting to a standalone component or mixin
        isSelectToolActive() {
            return this.selectedTool.value === TOOLS.SELECT.value;
        },
        isDeleteToolActive() {
            return this.selectedTool.value === TOOLS.DELETE.value;
        },
        isLabelToolActive() {
            return this.selectedTool.value === TOOLS.LABEL.value;
        },
        isRectToolActive() {
            return this.selectedTool.value === TOOLS.RECT.value;
        },
        isResizeToolActive() {
            return this.selectedTool.value === TOOLS.RESIZE.value;
        },
        isCommentToolActive() {
            return this.selectedTool.value === TOOLS.COMMENT.value;
        },
        // TOOL BAR END

        isViewMode() {
            return this.mode === MODES.VIEW;
        },

        isEditMode() {
            return this.mode === MODES.EDIT;
        },

        indicatorDotStyles() {
            const width = this.widthOfBar();
            const diameter = width ? `${width}px` : '100%';

            return {
                height: diameter,
                width: diameter,
            };
        },

        indicatorStyles() {
            const width = this.widthOfBar();
            const topOffset = width ? `-${width + 2}px` : '-7px';

            return {
                width,
                top: topOffset,
            };
        },

        videoLength() {
            if (!this.video) {
                return 1;
            }
            return this.video.duration;
        },

        isLoading() {
            return this.imageStatus === STATUS_LOADING || this.loading || this.imageStatus === STATUS_PENDING;
        },
        isReady() {
            return this.imageStatus === STATUS_READY;
        },
        filteredTracks() {
            // Filter the tracks that are inside the safety zone
            const filteredTracks = [];
            this.tracks.forEach((_, key) => {
                filteredTracks[key] = this.tracks[key]; // .filter((item) => isViolation(item, { zoneList: item.rois }));
                if (filteredTracks[key].length === 0) {
                    delete filteredTracks[key];
                }
            });
            return filteredTracks;
        },
        imageConfig() {
            return {
                image: this.thumbnail,
            };
        },
        pauseIcon() {
            return this.videoPaused ? 'play' : 'pause';
        },
        fullScreenIcon() {
            return this.isFullscreen ? 'fullscreen-exit' : 'fullscreen';
        },

        videoStyles() {
            return {
                width: 'auto',
                objectFit: this.isFullscreen ? 'fill' : 'contain',
                maxHeight: `${this.containerHeight}px`,
                // maxWidth: `${this.containerWidth}px`,
            };
        },

        wrapperStyles() {
            if (!this.video) {
                return { };
            }
            const { videoHeight, videoWidth } = this.video;
            return {
                maxWidth: `${videoWidth}px`,
                maxHeight: `${videoHeight}px`,
            };
        },
    },

    methods: {
        async handleModeButtonClick(mode) {
            switch (mode) {
                case MODES.EDIT:
                    this.pause();
                    this.disablePlay = true;

                    this.setMode(MODES.EDIT);
                    this.setTool(TOOLS.COMMENT);
                    break;
                case MODES.VIEW:
                    if (this.mode === MODES.EDIT) {
                        this.setTool(TOOLS.SELECT);
                        this.handleSaveAnnotationFrame();

                        this.saveVideoAnnotations(this.videoId);
                    }

                    this.disablePlay = false;
                    this.setMode(MODES.VIEW);
                    break;
                default:
                    break;
            }
        },

        handleClickVideo() {
            if (!this.isDragging) {
                this.togglePlayVideo();
            }
        },
        onDropdownActiveChange(val) {
            this.isDropdownActive = val;
        },

        doShowSafeIndicator(isSafe, maxRisk) {
            return isSafe && maxRisk > 1.28; // FIX-- Extract into a constant for risk score.
        },

        handleFullscreenChange() {
            this.isFullscreen = !!document.fullscreenElement;
        },

        sortAndFilterDistanceData(distanceData) {
            const valueMap = {
                safe: 0,
                caution: 1,
                danger: 2,
            };

            // Filter out safe values, check if result is greater than 1, if so show all, else show only danger or caution score.
            const filteredRiskScores = distanceData.filter((item) => item.riskLevel === 'caution' || item.riskLevel === 'danger');

            if (filteredRiskScores.length > 1) {
                return filteredRiskScores.sort((a, b) => valueMap[a.riskLevel] - valueMap[b.riskLevel]);
            }
            return filteredRiskScores;
        },

        getRiskLevelClass(rawLevel) {
            switch (rawLevel) {
                case 'safe':
                    return 'low-risk';
                case 'caution':
                    return 'medium-risk';
                case 'danger':
                    return 'high-risk';
                default:
                    return '';
            }
        },

        transformRiskLevel(rawLevel) {
            switch (rawLevel) {
                case 'safe':
                    return 'low';
                case 'caution':
                    return 'moderate';
                case 'danger':
                    return 'elevated';
                default:
                    return '';
            }
        },

        transformDistanceType(rawType) {
            return rawType.replace('-', ' ');
        },

        getTimeStampText(time = 0) {
            const minutes = Math.floor(time / 60);
            const seconds = Math.floor(time % 60);
            const milliseconds = Math.floor((time % 1) * 100);

            // Pad with zeros as needed
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            const formattedMilliseconds = String(milliseconds).padStart(2, '0');

            return `${formattedMinutes > 0 ? `${formattedMinutes}:` : ''}${formattedSeconds}.${formattedMilliseconds}`;
        },

        overlayStyles() {
            return {
                display: this.videoPaused ? 'block' : 'none',
            };
        },

        getProgressStyles() {
            const offset = this.barOffset(this.currentFrame);

            return {
                left: '0',
                bottom: '0',
                width: `${offset}px`,
            };
        },

        toggleFullScreen() {
            if (!document.fullscreenElement) {
                this.$refs.videoContainer.requestFullscreen();
            } else {
                document.exitFullscreen();
            }
        },

        handleClickScrubber(e) {
            e.preventDefault();
            e.stopPropagation();

            if (this.mode === MODES.EDIT) {
                return;
            }

            const rect = e.currentTarget.getBoundingClientRect();
            const xOffset = e.clientX - rect.left;
            const frameNum = Math.round(xOffset / this.widthOfBar());
            this.seekToFrame(frameNum);
        },

        handleKeyUp(e) {
            e.preventDefault();

            switch (e.key) {
                case ' ':
                    if (this.isReady) {
                        if (this.isPlaying) {
                            this.togglePlayVideo();
                            return;
                        }
                        this.play();
                    }
                    break;
                case 'ArrowRight':
                    if (this.isPlaying && this.mode !== MODES.EDIT) {
                        if (this.currentFrame < this.totalVideoFrameCount - 1) {
                            this.seekToFrame(this.currentFrame + 1);
                        }
                    }
                    break;
                case 'ArrowLeft':
                    if (this.isPlaying && this.mode !== MODES.EDIT) {
                        if (this.currentFrame > 0) {
                            this.seekToFrame(this.currentFrame - 1);
                        }
                    }
                    break;
                default:
                    break;
            }
        },

        handleScrubberMouseDown(e) {
            e.preventDefault();
            e.stopPropagation();

            if (this.video) {
                this.wasPlaying = !this.video.paused;
                this.pause();

                this.isDragging = true;
                this.startX = e.clientX;

                const scrubberBar = document.getElementById('videoScrubberBar');
                this.currentX = parseInt(scrubberBar.offsetLeft, 10) || 0;

                document.addEventListener('mousemove', this.handleDragging);
                document.addEventListener('mouseup', this.handleStopDragging);
            }
        },

        handleDragging(e) {
            e.preventDefault();

            if (!this.isDragging || this.mode === MODES.EDIT) return;

            const scrubberBar = document.getElementById('videoScrubberBar');
            if (!scrubberBar) return;

            const containerDiv = document.getElementById('videoScrubberContainer');
            const containerRect = containerDiv.getBoundingClientRect();
            const minClientX = containerRect.left;
            const containerWidth = containerDiv.offsetWidth;

            let offset = Math.floor(e.clientX - minClientX);

            if (offset < 0) {
                offset = 0;
            } else if (offset > containerWidth - scrubberBar.offsetWidth) {
                offset = containerWidth - scrubberBar.offsetWidth;
            }

            const percentage = offset / containerWidth;
            const frameNum = Math.round(percentage * this.totalVideoFrameCount);
            this.seekToFrame(frameNum);
        },

        handleStopDragging(e) {
            e.preventDefault();
            e.stopPropagation();

            this.isDragging = false;
            this.handleRangeMouseUp();
            document.removeEventListener('mousemove', this.handleDragging);
            document.removeEventListener('mouseup', this.handleStopDragging);
        },

        handleRangeMouseUp() {
            // We want to check if video was playing before we started seeking and resume playing if it was
            if (this.wasPlaying && this.video) {
                this.video.play();
                this.play();
            }
        },

        handleRangeChange(e) {
            this.seekToFrame(e.target.value);
        },

        getCurrentFrame() {
            if (!this.video || !this.fps) {
                return 0;
            }
            return Math.round(this.video.currentTime * this.fps);
        },

        togglePlayVideo() {
            if (this.video) {
                if (this.video.paused && !this.disablePlay) {
                    this.video.play();
                    this.play();
                    this.videoPaused = false;
                } else {
                    this.pause();
                }
            }
        },

        onVideoLoad() {
            const videoRef = this.$refs[`video-${this.url}`];
            this.video = videoRef;
            videoRef.focus();

            this.resize();
        },

        onImageLoad() {
            function getCanvasFromImage(image) {
                const canvas = document.createElement('canvas');
                canvas.width = image.width * 2;
                canvas.height = image.height * 2;
                const ctx = canvas.getContext('2d');
                ctx.filter = 'blur(5px)';
                ctx.drawImage(image, 0, 0);
                return canvas;
            }

            const { image } = this.$refs;
            this.thumbnail = getCanvasFromImage(image);
            this.imageStatus = STATUS_READY;
        },

        onVideoError() {
            const match = this.url.match(/Expires=(\d+)/);
            if (match) {
                if (this.$date().unix() > Number(match[1])) {
                    this.message = 'The link to this video has expired after 60 minutes of inactivity. Please refresh your browser window to reload the video.';
                    return;
                }
            }
            this.isError = true;
            this.message = 'This video has been deleted as your data retention policy is set to 30 days. To review or modify this policy please contact us.';
        },

        clearAnimationCanvas() {
            this.clearObjectBBs();
            this.clearObjectProximity();
        },

        startVideoAnimation() {
            this.video.requestVideoFrameCallback(this.ticker);
            requestAnimationFrame(this.updateCanvas.bind(this, null));
        },

        stopVideoAnimation() {
            this.video.cancelVideoFrameCallback(this.ticker);
            cancelAnimationFrame(this.updateCanvas.bind(this, null));
        },

        onVideoPlay() {
            if (this.video.currentTime < 1) {
                this.clearAnimationCanvas();
            }
            this.startVideoAnimation();
        },

        updateBbs(previousFrameNumber, frameNumber, lerp) {
            if (previousFrameNumber !== frameNumber) {
                const frameDetections = this.tracks.map((track) => {
                    const frameNums = track?.map((f) => f.frame_number);
                    const lookupFrameNumber = binarySearchClosestSmallerNumber(
                        frameNums,
                        frameNumber,
                    );
                    const firstFrame = track?.find(
                        (f) => f.frame_number === lookupFrameNumber,
                    );
                    if (!firstFrame) {
                        return undefined;
                    }
                    let combinedFrame = firstFrame;
                    const firstFrameIndex = track?.findIndex(
                        (f) => f.frame_number === lookupFrameNumber,
                    );
                    const nextFrame = track[firstFrameIndex + 1];
                    const maxFrameDifference = 5;
                    if (nextFrame && (nextFrame.frame_number - frameNumber) < maxFrameDifference) {
                        const denominator = nextFrame.frame_number - firstFrame.frame_number;
                        let interp = 0;

                        if (denominator > 0) {
                            interp = (frameNumber - firstFrame.frame_number) / denominator;
                        }

                        if ((nextFrame.frame_number / this.fps - firstFrame.frame_number / this.fps)
                            > MAX_INTERPOLATION_INTERVAL / 1000) {
                            interp = 0;
                        }

                        combinedFrame = {
                            ...firstFrame,
                            bb: {
                                b: lerp(
                                    firstFrame.bb.b,
                                    nextFrame.bb.b,
                                    interp,
                                ),
                                l: lerp(
                                    firstFrame.bb.l,
                                    nextFrame.bb.l,
                                    interp,
                                ),
                                r: lerp(
                                    firstFrame.bb.r,
                                    nextFrame.bb.r,
                                    interp,
                                ),
                                t: lerp(
                                    firstFrame.bb.t,
                                    nextFrame.bb.t,
                                    interp,
                                ),
                            },
                        };
                    } else {
                        const opacity = 1 - (this.video.currentTime - firstFrame.frame_number / this.fps)
                            / (BOX_FADE_OUT_TIME / 1000);
                        this.$set(combinedFrame, 'opacity', Math.min(Math.max(opacity, 0), 1));
                    }

                    combinedFrame.line = {
                        points: track
                            .filter((_, i) => i <= firstFrameIndex)
                            .map((trackFrame, i) => [
                                (trackFrame.bb.l + trackFrame.bb.r) / 2,
                                trackFrame.bb.b,
                                ...(i === firstFrameIndex
                                    ? [
                                        (combinedFrame.bb.l
                                            + combinedFrame.bb.r)
                                            / 2,
                                        combinedFrame.bb.b,
                                    ]
                                    : []),
                            ])
                            .flat(),
                    };

                    return combinedFrame;
                }).filter((d) => d !== undefined && d !== null && !(d.object_class === 'Car' && d.speed < 3));

                if (frameDetections.length !== 0) {
                    this.detections.results_generic = frameDetections.map(
                        (d) => ({
                            ...d,
                            isViolation: isViolation(d, { bb: d.bb }),
                        }),
                    );
                } else {
                    this.clearObjectBBs();
                    this.clearObjectProximity();
                }
            }
        },

        updateProximity(previousFrameNumber, frameNumber) {
            if (previousFrameNumber !== frameNumber) {
                const frameNums = this.proximityTracks?.map((frame) => frame.frame_number) ?? [];
                const lookupFrameNumber = binarySearchClosestSmallerNumber(
                    frameNums,
                    frameNumber,
                );
                const firstFrame = this.proximityTracks?.find(
                    (f) => f.frame_number === lookupFrameNumber,
                ) ?? null;
                if (!firstFrame) {
                    return;
                }
                if (frameNumber > lookupFrameNumber) {
                    this.clearObjectProximity();
                    return;
                }

                this.detections.results_proximity = firstFrame.classified_distances;
            }
        },

        updateCanvas(previousFrameNumber) {
            if (!this.displayTags) {
                this.clearAnimationCanvas();
                return;
            }

            const lerp = (a, b, t) => a + (b - a) * t;

            const frameNumber = Math.round(this.video.currentTime * this.targetFPS);
            this.currentFrame = frameNumber;

            this.render(previousFrameNumber, frameNumber, lerp);

            requestAnimationFrame(this.updateCanvas.bind(this, frameNumber));
        },

        ticker(now, metadata) {
            const mediaTimeDiff = Math.abs(
                metadata.mediaTime - this.lastMediaTime,
            );
            const frameDiff = Math.abs(
                metadata.presentedFrames - this.lastFrameNumber,
            );
            const diff = mediaTimeDiff / frameDiff;
            if (
                diff
                && diff < 1
                && this.fpsRounder.length < 50
                && this.video.playbackRate === 1
            ) {
                this.fpsRounder.push(diff);
                this.fps = Math.round(1 / this.getAverageFps());
            }
            this.lastMediaTime = metadata.mediaTime;
            this.lastFrameNumber = metadata.presentedFrames;
            this.video.requestVideoFrameCallback(this.ticker);

            if (
                this.timestamps.length === 0
                && this.tracks[0]
                && this.fps !== 0
            ) {
                this.calculateTimestamps();
            }
        },

        getAverageFps() {
            // handle empty array
            if (!this.fpsRounder.length) {
                return 0;
            }

            // check for non-numeric values before adding to the sum
            const sum = this.fpsRounder.reduce((a, b) => (typeof b === 'number' ? a + b : a), 0);

            return sum / this.fpsRounder.length;
        },

        play() {
            this.isPreview = false;
            this.isPlaying = true;
            this.videoPaused = false;
        },

        pause() {
            this.video.pause();
            this.videoPaused = true;
        },

        stop() {
            this.isPreview = true;
            this.isPlaying = false;
            this.videoPaused = true;
        },

        clearObjectBBs() {
            this.detections.results_generic = [];
        },

        clearObjectProximity() {
            this.detections.results_proximity = [];
        },

        resetFPS() {
            this.lastMediaTime = 0;
            this.lastFrameNumber = 0;
            this.fps = 0;
            this.fpsRounder = [];
        },

        resize() {
            this.$nextTick(() => {
                const { container, videoContainer } = this.$refs;

                if (videoContainer) {
                    const width = this.$refs.videoContainer.offsetWidth;
                    const height = this.$refs.videoContainer.offsetHeight;
                    this.videoContainerSize = {
                        width,
                        height,
                        ratio: width / height,
                    };
                    this.$nextTick(() => {
                        this.containerHeight = height;
                        this.containerWidth = width;
                    });
                }

                if (container) {
                    const width = this.$refs.container.offsetWidth;
                    const height = this.$refs.container.offsetHeight;
                    this.containerSize = {
                        width,
                        height,
                        ratio: width / height,
                    };
                }

                if (this.video) {
                    this.imageSize = {
                        width: this.video.clientWidth,
                        height: this.video.clientHeight,
                    };
                    const { translate, scale } = getTansformationConfig({
                        clientWidth: this.video.clientWidth,
                        clientHeight: this.video.clientHeight,
                        mediaWidth: this.video.videoWidth,
                        mediaHeight: this.video.videoHeight,
                    });
                    this.translate = translate;
                    this.scale = scale;

                    this.render(this.previousFrameNumber, this.currentFrame, 0, true);
                }
            });
        },

        seekToTimestamp(timestamp) {
            if (this.mode === MODES.EDIT) {
                return;
            }

            const frameNum = Math.floor(timestamp * this.targetFPS);
            this.seekToFrame(frameNum);
        },

        seekToFrame(frameNum) {
            if (this.mode === MODES.EDIT) {
                return;
            }

            this.currentFrame = frameNum;
            this.video.currentTime = frameNum / this.targetFPS;
        },

        render(previousFrameNumber, frameNumber, lerp, skipChange = false) {
            if (frameNumber !== previousFrameNumber || skipChange) {
                this.reDrawScrubberLine(frameNumber);
                this.updateBbs(previousFrameNumber, frameNumber, lerp);
                this.updateProximity(previousFrameNumber, frameNumber);
                this.updateAnnotationFrame(frameNumber);
            }
        },

        calculateTimestamps() {
            this.timestamps = Object.keys(this.filteredTracks).map(
                (id) => {
                    const track = this.filteredTracks[id];
                    let highestRiskLevel = 0;
                    const timestamps = track.map(
                        (frame) => {
                            const riskLevel = frame?.risk_level ?? 0;
                            highestRiskLevel = Math.max(highestRiskLevel, riskLevel);
                            return Math.floor(
                                ((frame.frame_number) / this.fps) * 2 * 1000,
                            ) / 1000;
                        },
                    );

                    const startFrame = 0;
                    const endFrame = track.length - 1;
                    const firstFrame = track[startFrame];
                    const objectClass = firstFrame.object_class[0].toUpperCase()
                        + firstFrame.object_class.substring(1).toLowerCase();
                    let violationText = `${objectClass}`;
                    if (firstFrame.object_class === 'Person') {
                        const ppeDetections = getPPEDetections(firstFrame);
                        violationText = `${objectClass} ${ppeDetections.indexOf('vis') !== -1 ? 'No Hi-vis' : ''} ${ppeDetections.indexOf('hat') !== -1 ? 'No Hat' : ''}`;
                    }

                    return {
                        frame_number: startFrame,
                        start: timestamps[startFrame],
                        end: timestamps[endFrame],
                        violationText,
                        riskLevel: highestRiskLevel,
                    };
                },
            ).filter((frame) => frame !== null);
        },

        formatTime(timestamp) {
            const minutes = Math.floor(timestamp / 60);
            const seconds = Math.floor(timestamp) % 60;
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            return `${formattedMinutes}:${formattedSeconds}`;
        },

        barOffset(frameNum) {
            return frameNum * this.widthOfBar();
        },

        scrubberBarStyles() {
            if (!this.video) {
                return { };
            }

            const width = this.widthOfBar();
            return {
                position: 'absolute',
                width: `${width}px`,
                left: `${this.barOffset(this.currentFrame)}px`,
            };
        },

        getToolStyles(count) {
            return {
                left: 0,
                top: 0,
                width: `${this.widthOfBar()}px`,
                height: `${10 * count}px`,
            };
        },

        getBarStyles(frameNum, count, maxRisk, isBold = false) {
            if (!this.video) {
                return { };
            }

            const colorWeight = isBold ? 1 : 0.5;

            // Calculating background color form max risk
            let backgroundColor = 'rgba(0,0,0,0)';
            if (maxRisk <= 2.8) {
                backgroundColor = `rgba(126,194,180, ${colorWeight})`;
            } else if (maxRisk <= 3.6) {
                backgroundColor = `rgba(247,138,56, ${colorWeight})`;
            } else {
                backgroundColor = `rgba(230,74,74, ${colorWeight})`;
            }

            return {
                height: `${count * this.barScale}px`,
                width: `${this.widthOfBar()}px`,
                position: 'absolute',
                left: `${this.barOffset(frameNum)}px`,
                display: 'inline-block',
                background: backgroundColor,
            };
        },

        widthOfBar() {
            const progressBarRef = document.getElementById('videoScrubberContainer');
            const width = progressBarRef?.offsetWidth || 0;
            return (width / this.totalVideoFrameCount);
        },

        startSteadyPlayback() {
            this.isCustomPlayback = true;
            this.video.pause(); // Pause native playback
            this.lastTimestamp = performance.now();
            this.playFrames();
        },

        stopSteadyPlayback() {
            this.isCustomPlayback = false;
            this.video.play(); // Resume native playback
        },

        playFrames(timestamp) {
            if (!this.isCustomPlayback) return;

            const deltaTime = timestamp - this.lastTimestamp;
            if (deltaTime >= (1000 / this.targetFPS)) {
                // Update video time
                const frameTime = 1 / this.targetFPS;
                this.video.currentTime += frameTime;
                this.lastTimestamp = timestamp;

                // Check if we've reached the end
                if (this.video.currentTime >= this.video.duration) {
                    this.isCustomPlayback = false;
                    return;
                }
            }

            requestAnimationFrame(this.playFrames);
        },

        reDrawScrubberLine(frameNumber) {
            document.querySelectorAll('.frame-marker').forEach((el) => {
                el.classList.remove('current-frame');
            });

            // Add highlight to current frame marker
            const currentMarker = document.querySelector(`.frame-${frameNumber}`);
            if (currentMarker) {
                currentMarker.classList.add('current-frame');
            }

            const scrubberBar = document.getElementById('videoScrubberBar');
            if (scrubberBar) {
                scrubberBar.style.left = `${this.barOffset(frameNumber)}px`;
            }
        },
        calcRiskScoreClass(score) {
            return getRiskScoreClass(score);
        },
        copyVideoId() {
            navigator.clipboard.writeText(this.videoId);
            this.$buefy.toast.open({
                message: 'Video URL copied to clipboard',
                type: 'is-success',
            });
        },

        async downloadVideo() {
            if (!this.url) {
                this.$buefy.toast.open({
                    message: 'Failed to download video',
                    type: 'is-danger',
                });
                return;
            }

            try {
                // Fetch the video as a blob
                const response = await fetch(this.url);
                const blob = await response.blob();

                // Create a blob URL
                const blobUrl = window.URL.createObjectURL(blob);

                // Create download link
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `video-${this.videoId}.mp4`);

                // Trigger download
                document.body.appendChild(link);
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);

                this.$buefy.toast.open({
                    message: 'Video download started',
                    type: 'is-success',
                });
            } catch (error) {
                console.error('Download failed:', error);
                this.$buefy.toast.open({
                    message: 'Failed to download video',
                    type: 'is-danger',
                });
            }
        },
    },
    beforeDestroy() {
        this.stopSteadyPlayback();
    },
};
</script>

<style lang="scss" scoped>
.media-video {
    min-width: 300px;
    min-height: 200px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &__container {
        position: relative;
        height: calc(100vh - 300px);
        display: flex;
        justify-content: center;
        align-items: center;

        &__wrapper {
            display: flex;
            flex-direction: row;
            gap: 10px;
            height: 100%;
        }
    }

    &__video-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.is-fullscreen {
            max-width: 100vw !important;
            max-height: 100vh !important;
        }
    }

    &__scene {
        position: relative;
        width: auto;
        height: 100%;
        background-color: rgba($dark, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__wrapper {
        position: relative;
        overflow: visible;
        height: auto;
        max-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    video {
        position: relative;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
    }

    &__canvas {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 30;
        pointer-events: none;
    }

    &__error {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: $grey-light;
        font-size: 0.9em;
        cursor: default;
    }

    &__overlay {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2147483647;
        opacity: 0;
        transition: opacity 0.3s ease;

        &.is-visible {
            opacity: 1;
        }

        &__container {
            position: absolute;
            bottom: 10px;
            width: 90%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            padding: 10px;
            z-index: 100;

            left: 50%;
            transform: translateX(-50%);

            background-color: rgba($dark, 0.6);
            transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
            border-radius: 20px;

            &:hover, &.is-active {
                background-color: rgba($dark, 1);
            }

            &__left {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
            }

            &__right {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                &__right {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                }

                &__left {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            &__timestamp {
                font-size: 12px;
            }
        }

        &__track {
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0;
        }

        &__progress {
            width: 100%;

            background-color: lightgrey;
            z-index: 99;
            height: 5px;

            &-bar {
                width: 100%;
                display: flex;
                height: 1rem;
                overflow: hidden;
                font-size: .75rem;
                background-color: darkgrey;
                border-radius: .25rem;

                z-index: 110;
            }
        }

        &__bars {
            width: 100%;
            height: 30px;
            z-index: 30;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-end;
            position: relative;
        }

        &__controls {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 30px;
            z-index: 30;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__preview {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 101;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        &-bg {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 20;
            background: rgba($dark, 0.6);
            border-radius: $radius;
        }

        img {
            z-index: 20;
            object-fit: contain;
            max-width: 100%;
            max-height: calc(100vh - 300px);
            width: auto;
            height: 100%;
        }

        button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 30;
            @include button-reset;
            width: 80px;
            height: 80px;
            border-radius: 80px;
            background: rgba($white, 0.4);
            border: 2px solid $white;
            color: $white;
            @include transition-default;

            &:hover {
                background: rgba($primary, 0.2);
                border-color: $primary;
                color: $primary;
            }
        }
    }

    .low-risk {
        color: $low;
    }

    .medium-risk {
        color: $moderate;
    }

    .high-risk {
        color: $critical;
    }
}

.frame-marker {
    &.current-frame {
        background-color: 2px solid white;
        z-index: 1;
        box-shadow: 0 0 5px rgba(255,255,255,0.5);

    }
}

::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    outline: none;
    cursor: pointer;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: white;
        cursor: pointer;
        transition: all .15s ease-in-out;

        &:hover {
            transform: scale(1.2);
        }
    }

    &::-moz-range-thumb {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: white;
        cursor: pointer;
        transition: all .15s ease-in-out;

        &:hover {
            transform: scale(1.2);
        }
    }
}

.scrubber-bar {
    background-color: white;
    z-index: 50;
    position: absolute;
    border-radius: 10px;
    transform: translateY(-50%);
    height: 15px,
}

.tooltip {
    &-row {
        padding: 10px;
        border-bottom: 1px solid #ccc;

        &:last-child {
            border-bottom: none;
        }
    }

    &-grid {
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: 5px;
    }
}

.play-pause-button, .full-screen-button, .pop-up-button {
    padding: 15px;
    border-radius: 50%;

    transition: all 0.3s ease;
    background: none;
    border: none;

    &:hover {
        transform: scale(1.1);
    }
}

video::-webkit-media-controls-enclosure {
    display: none !important;
}

.indicator-parent {
    display: flex;
    flex-direction: column-reverse;
    height: fit-content;
    position: absolute;
    transform-origin: bottom;
    gap: 2px;
}

.safe-indicator {
    background-color: $success;
    border-radius: 50%;
}

.annotated-indicator {
    background-color: $secondary;
    border-radius: 50%;
}

.scene-fullscreen {
    display: flex;
    align-items: center;
}

.toolbar {
    z-index: 600;
    height: 100%;
    padding: 5px;
    border-radius: 10px;

    &__edit {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .mode-button {
        width: 50px;
    }

    .is-divider {
        margin: 1px;
    }

    .is-delete {
        background-color: $red;
        color: $primary;

        &:hover {
            background-color: red;
        }
    }
}

.edit-button {
    background-color: $white;
    color: $dark;
}
</style>
